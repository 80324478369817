<template>
    <div class="col-md-8">
        <div class="canvas-wrap">
            <canvas width="1720" height="1080" id="three"></canvas>
        </div>
        <div class="left-info">
            <span style="margin-bottom: 25px;">時間軸</span>
            <div class="left-info-silder">
                <div style="padding: 0 25px;" class="time-silder">
                    <Slider style="width: 100%" :min="1" size="sm" :max="timeline_item[measure_type_id].length"
                        :step="1" :logarithmic="true" v-model="timeline_index"
                        :format="v => `${timeline_item[measure_type_id][Math.round(v) - 1].time}`" />
                </div>
                <div class="left-info-timeline">
                    <span v-for="(item, index) in timeline_item[measure_type_id]" :key="index">{{ item.time }}</span>
                </div>
            </div>
        </div>
        <div class="left-info">
            <span>數值區域</span>
            <div class="left-info-input">
                <span>最小值</span>
                <input type="text" v-model="measure_input_min" @change="m_minChange">
            </div>
            <div class="left-info-silder  ">
                <Slider style="width: 100%" :min="0" size="sm" :max="coating_thickness_max" :step="-1"
                    :logarithmic="true" v-model="measure_range" />
            </div>
            <div class="left-info-input">
                <span>最大值</span>
                <input type="text" v-model="measure_input_max" @change="m_maxChange">
            </div>
        </div>
        <div class="left-info colormap">
            <span>colormap</span>
            <div class="left-info-silder  ">
                <Slider style="width: 100%" :min="20" size="sm" :max="140" :step="-1" :logarithmic="true"
                    v-model="color_range"
                    :format="v => `${v == color_range[0] ? measure_range[0] : v == color_range[1] ? measure_range[1] : '...'}`" />
            </div>
        </div>
        <div class="left-info"
            v-if="singleMeasure && singleMeasure.tank && singleMeasure.tank.id && singleMeasure.tank.id == 10">
            <span>測量點透明度</span>
            <div class="left-info-silder  ">
                <Slider style="width: 100%" :min="0" size="sm" :max="100" :step="1" :logarithmic="true"
                    v-model="dot_opcity" />
            </div>
        </div>
        <div class="left-info"
            v-if="singleMeasure && singleMeasure.tank && singleMeasure.tank.id && singleMeasure.tank.id == 10">
            <span>模型完整度</span>
            <div class="left-info-silder  ">
                <Slider style="width: 100%" :min="0" size="sm" :max="100" :step="1" :logarithmic="true"
                    v-model="chinasteel_opcity" />
            </div>
        </div>
    </div>
    <div class="col-md-4 right-info">
        <img v-if="tankBase64Img && tankBase64Img.data && tankBase64Img.data.base64Image"
            v-bind:src="'data:image/jpeg;base64,' + tankBase64Img.data.base64Image" style="width: 100%;">

        <!-- <img v-if="measureData.list && measureData.list[0].tank && measureData.list[0].tank.id == 3"
            src="https://display-backend.droxotech.com/storage/messageImage_1691646492626.jpg" style=" width: 100%;"> -->
        <table class="right-info-table" v-if="singleMeasure && singleMeasure.tank">
            <tbody>
                <tr>
                    <td style="width: 120px;">桶槽名稱：</td>
                    <td>{{ singleMeasure.tank.name }}</td>
                </tr>
                <tr>
                    <td>桶槽半徑(m)：</td>
                    <td>{{ singleMeasure.tank.radius }}</td>
                </tr>
                <tr>
                    <td>桶槽高度(m)：</td>
                    <td>{{ singleMeasure.tank.height }}</td>
                </tr>
                <tr>
                    <td>測量數量：</td>
                    <td>{{ singleMeasure.tank_measurement_details.length }}</td>
                </tr>
                <tr>
                    <td>場域位置：</td>
                    <td>{{ singleMeasure.tank.area.name }}</td>
                </tr>
                <tr>
                    <td>顯示模式：</td>
                    <td>
                        <button v-if="timeline_item.hasOwnProperty('1') && timeline_item['1'].length != 0"
                            class=" m_type_btn" v-bind:class="{ 'active': measure_type_id == 1 }"
                            @click=" changeMeasureType(1)">
                            壁厚
                        </button>
                        <span
                            v-if="timeline_item.hasOwnProperty('1') && timeline_item.hasOwnProperty('2') && timeline_item['1'].length != 0 && timeline_item['2'].length != 0">／</span>
                        <button v-if="timeline_item.hasOwnProperty('2') && timeline_item['2'].length != 0"
                            class="m_type_btn  " v-bind:class="{ 'active': measure_type_id == 2 }"
                            @click="changeMeasureType(2)">
                            膜厚
                        </button>
                    </td>
                </tr>
                <tr>
                    <td>測定報告：</td>
                    <td>
                        <span style="color: rgb(2 77 161);cursor: pointer;border-bottom: 1px solid;"
                            v-if="tankMeasurementFileBase64 && tankMeasurementFileBase64.data && tankMeasurementFileBase64.data.base64Image"
                            @click="saveB64Pdf(tankMeasurementFileBase64.data.base64Image)">測定報告</span>
                    </td>
                </tr>
                <tr>
                    <td>桶槽內容物：</td>
                    <td>{{ singleMeasure.tank.contents }}</td>
                </tr>
                <tr
                    v-if="measureData.list && measureData.list[0].tank && measureData.list[0].tank.id == 2 && measure_type_id == 1">
                    <td>聲波速度(m/s)：</td>
                    <td>5901</td>
                </tr>
            </tbody>
        </table>
        <button class="search-btn mt-5" @click="stop = true, closeView()">
            返回
        </button>
    </div>
    <!-- hover -->
    <div class="hoverTip">
        <span v-if="showDetail.hover_show && showDetail.info && showDetail.info.id"> 測量點ID : {{
            showDetail.info.index
        }}</span>
        <span v-if="showDetail.hover_show && showDetail.info && showDetail.info.id"> 測量值 : {{
            (Math.round(parseFloat(showDetail.info.coating_thickness) * 100) / 100).toFixed(2)
        }}</span>
    </div>
    <!-- pop -->
    <div class="pop-panel" v-if="showDetail.show && showDetail.info && showDetail.info.id" @click="hideDetail()">
        <div class="pop-panel-container py-4" style="width: 700px;" @click=" $event.stopPropagation()">
            <div class="pop-panel-title" style="text-align: center; color: #159BD7;">桶槽資訊</div>
            <div class="pop-panel-inner"><span class="xmark" @click=" hideDetail()">
                    ✕
                </span>
                <div class="row">
                    <div class="col-12 ">
                        <img v-if="displayBase64Img && displayBase64Img.data && displayBase64Img.data.base64Image"
                            class="mb-2" v-bind:src="'data:image/jpeg;base64,' + displayBase64Img.data.base64Image"
                            style="width: 100%;">
                        <div class="loaderPanel" v-if="!displayBase64Img">
                            <span class="loader"></span>
                        </div>
                    </div>
                    <div class="col-4 mt-3">
                        <div class="tank-info">
                            <b>測量點ID :</b>
                            <div>{{ showDetail.info.index }}</div>
                        </div>
                        <div class="tank-info">
                            <b>方位 :</b>
                            <div> {{ showDetail.info.degree }}</div>
                        </div>
                    </div>
                    <div class="col-4  mt-3">
                        <div class="tank-info">
                            <b>測量位置 :</b>
                            <div> x : {{ (Math.round(parseFloat(showDetail.info.x) * 100) / 100).toFixed(2) }}</div>
                            <div> y : {{ (Math.round(parseFloat(showDetail.info.y) * 100) / 100).toFixed(2) }}</div>
                            <div> z : {{ (Math.round(parseFloat(showDetail.info.z) * 100) / 100).toFixed(2) }}</div>
                        </div>
                    </div>
                    <div class="col-4  mt-3">
                        <div class="tank-info">
                            <b>測量值(mm) :</b>
                            <div> {{
                                (Math.round(parseFloat(showDetail.info.coating_thickness) * 100) / 100).toFixed(2) }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import Slider from '@vueform/slider'
import '@vueform/slider/themes/default.css'
import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader.js';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js';

export default {
    searchUserList: {},
    name: 'HcpUpload',
    components: {
        Slider
    },
    props: ["measureData", "closeView"],
    data() {
        return {
            number: 10,
            msg: "",
            stop: false,
            //目前最大最小
            coating_thickness_min: -1,
            coating_thickness_max: -1,
            color_range: [20, 140],
            measure_range: [6, 7],
            measure_input_min: -1,
            measure_input_max: -1,
            timeline_index: 1,
            //measure_type_id:測量型態
            measure_type_id: 1,
            timeline_item: {
                1: [
                    { measure_id: 1, time: '2020/03/23' },
                    { measure_id: 2, time: '2020/04/11' },
                    { measure_id: 3, time: '2020/08/06' },
                ],
                2: [
                    { measure_id: 4, time: '2022/03/23' },
                    { measure_id: 5, time: '2022/04/11' },
                    { measure_id: 6, time: '2022/08/06' },
                    { measure_id: 7, time: '2022/08/09' },
                ],
            }
            ,
            showDetail: {
                //資料有變動
                isChange: false,
                show: false,
                info: null,
                hover_show: true,
            },
            tankInfo: {
                radiusTop: 10.98,
                radiusBottom: 10.98,
                height: 11,
                radius: 10,
                segments: 45,
                measureNum: 0,
            },
            //單包測量資料
            singleMeasure: {},
            //滑鼠事件記錄
            onMouseDownDotId: null,
            cameraX: 0,
            cameraY: 120,
            cameraZ: 120,
            // chinasteel_object
            chinasteel_opcity: 50,
            dot_opcity: 100,
        };
    },
    watch: {
        'timeline_index': function (val) {
            // console.log('timeline_index id :' + this.timeline_item[String(this.measure_type_id)][val - 1].id)
            var newId = this.timeline_item[String(this.measure_type_id)][val - 1].id;
            // self.showDetail.isChange = true;
            this.singleMeasureInit(newId);
        },
        'measure_range': function (val) {
            this.measure_input_min = val[0];
            this.measure_input_max = val[1];
            this.dot_redraw();
        },
        'color_range': function () {
            this.dot_redraw();
        },
        // tankMeasurementFileBase64: function (val) {
        //     console.log('tankMeasurementFileBase64' + val)
        // }
    },
    mounted() {
        //預設
        // this.singleMeasure = this.measureData.list[0]; 
        this.singleMeasureInit(this.measureData.list[0].id);
        //現在顯示的模式
        this.measure_type_id = this.singleMeasure.measurement_type_id;
        //拿圖片 
        this.GetTankBase64Img(this.measureData.list[0].tank.image)
        //桶槽資料切換：膜厚,壁厚,桶槽資料初始化init
        this.tankDataInit();
    },
    computed: {
        ...mapState(
            {
                displayBase64Img: state => state.displayBase64Img,
                tankBase64Img: state => state.tankBase64Img,
                tankMeasurementFileBase64: state => state.tankMeasurementFileBase64,
            }
        ),
    },
    methods: {
        ...mapActions([
            'GetDropDownOrgList',
            'GetDropDownAreaList',
            'GetDropDownTankList',
            'GetSearchDisplayList',
            'GetDisplayBase64Img',
            'GetTankBase64Img',
            'GetTankMeasurementFileBase64'
        ]),
        initThree() {
            var self = this;

            const scene = new THREE.Scene()
            scene.background = new THREE.Color('#aaa')
            const canvas = document.querySelector('#three')
            const renderer = new THREE.WebGLRenderer({ canvas, antialias: true })
            const camera = new THREE.PerspectiveCamera(
                20,
                window.innerWidth / window.innerHeight,
                1,
                10000
            )
            camera.position.set(self.cameraX, self.cameraY, self.cameraZ);

            camera.layers.enable(0); // 啟用 layer 0
            camera.layers.enable(1); // 啟用 layer 1

            scene.position.setY(-7);
            var raycaster = new THREE.Raycaster();
            raycaster.layers.disable(0);
            raycaster.layers.enable(1);
            var mouse = new THREE.Vector2();

            function onMouseMove(event) {
                mouse.x = ((event.clientX - canvas.getBoundingClientRect().x) / canvas.getBoundingClientRect().width) * 2 - 1;
                mouse.y = - ((event.clientY - canvas.getBoundingClientRect().y) / canvas.getBoundingClientRect().height) * 2 + 1;
                raycaster.setFromCamera(mouse, camera);

                //取得 raycaster 射線和所有物件相交的陣列集合
                var intersects = raycaster.intersectObjects(scene.children);
                //計算後，將所有有相交的物件的顏色改變為紅色，如果只需要將第一個觸發事件，那就陣列的第一個物件改變顏色即可 
                if (intersects.length != 0) for (var i = 0; i < 1; i++) {
                    for (j = 0; j < scene.children.length; j++) {
                        if (scene.children[j].geometry && scene.children[j].name == 'tank_measurement_dot') {
                            scene.children[j].material.color.set(scene.children[j].deep);
                            // scene.children[j].geometry.scale(1 / 1.5, 1 / 1.5, 1 / 1.5);  
                            // scene.children[j].geometry.scale(1, 1, 1); 
                        }
                    }

                    if (i == 0 && intersects[i].object.name == 'tank_measurement_dot') {
                        intersects[i].object.material.color.set(0x00ffff);
                        // intersects[i].object.geometry.scale(1.5, 1.5, 1.5); 
                        self.showDetail.info = intersects[i].object.measureInfo;
                        self.showDetail.hover_show = true;
                        var _hoverEle = document.getElementsByClassName("hoverTip");
                        _hoverEle[0].style.left = event.clientX + 5 + 'px';
                        _hoverEle[0].style.top = event.clientY + 5 + 'px';
                        return;
                    }
                }
                self.showDetail.info = null;
            }
            function onMouseDown(event) {
                for (i = 0; i < scene.children.length; i++) {
                    if (scene.children[i].geometry && scene.children[i].name == 'tank_measurement_dot') {
                        scene.children[i].material.color.set(scene.children[i].deep);
                    }
                }
                //透過滑鼠點選的位置計算出 raycaster 所需要的點的位置，以螢幕中心為原點，值的範圍為-1到1. 
                mouse.x = ((event.clientX - canvas.getBoundingClientRect().x) / canvas.getBoundingClientRect().width) * 2 - 1;
                mouse.y = - ((event.clientY - canvas.getBoundingClientRect().y) / canvas.getBoundingClientRect().height) * 2 + 1;

                raycaster.setFromCamera(mouse, camera);
                //取得 raycaster 射線和所有物件相交的陣列集合
                var intersects = raycaster.intersectObjects(scene.children);
                //計算後，將所有有相交的物件的顏色改變為紅色，如果只需要將第一個觸發事件，那就陣列的第一個物件改變顏色即可 
                if (intersects.length != 0) for (var i = 0; i < 1; i++) {
                    if (i == 0 && intersects[i].object.name == 'tank_measurement_dot') {
                        self.onMouseDownDotId = intersects[i].object.measureInfo.id;
                    }
                }
            }
            function onMouseUp(event) {
                for (i = 0; i < scene.children.length; i++) {
                    if (scene.children[i].geometry && scene.children[i].name == 'tank_measurement_dot') {
                        scene.children[i].material.color.set(scene.children[i].deep);
                    }
                }
                //透過滑鼠點選的位置計算出 raycaster 所需要的點的位置，以螢幕中心為原點，值的範圍為-1到1. 
                mouse.x = ((event.clientX - canvas.getBoundingClientRect().x) / canvas.getBoundingClientRect().width) * 2 - 1;
                mouse.y = - ((event.clientY - canvas.getBoundingClientRect().y) / canvas.getBoundingClientRect().height) * 2 + 1;

                raycaster.setFromCamera(mouse, camera);
                //取得 raycaster 射線和所有物件相交的陣列集合
                var intersects = raycaster.intersectObjects(scene.children);
                //計算後，將所有有相交的物件的顏色改變為紅色，如果只需要將第一個觸發事件，那就陣列的第一個物件改變顏色即可 
                if (intersects.length != 0) for (var i = 0; i < 1; i++) {
                    if (i == 0 && intersects[i].object.name == 'tank_measurement_dot') {
                        if (self.onMouseDownDotId == intersects[i].object.measureInfo.id) {
                            self.showDetail.info = intersects[i].object.measureInfo
                            intersects[i].object.material.color.set(0x00ff00);
                            self.GetDisplayBase64Img(intersects[i].object.measureInfo.image)
                            self.showDetail.show = true;
                        }
                    }
                }
            }
            /*----光源----*/
            const pointLight = new THREE.PointLight(0xfffffff);
            const ambientLight = new THREE.AmbientLight(0xfffffff);
            pointLight.position.set(20, 5, 5);
            // scene.add(pointLight, ambientLight); 
            /*----光源輔助----*/
            //const lightHelper = new THREE.PointLightHelper(pointLight); 
            /*----網格輔助----*/
            // const gridHelper = new THREE.GridHelper(500, 20);
            // scene.add(lightHelper, gridHelper); 
            /*----滑鼠控制----*/
            const controls = new OrbitControls(camera, renderer.domElement);
            scene.add(pointLight, ambientLight);
            // scene.add(gridHelper); 
            /*----PolarGridHelper----*/
            const radius = 50;
            const radials = 16;
            const circles = 5;
            const divisions = 64;
            const helper = new THREE.PolarGridHelper(radius, radials, circles, divisions, "#dadada", "#9c9c9c");
            scene.add(helper);
            var t_id = this.measureData.list[0].tank_id;

            /*----載入桶槽模型----*/
            var chinasteel_object;
            //測量資料日期 this.singleMeasure.tank_measurement_details
            var measureDate = this.singleMeasure.date; // "2024-11-21"
            var modelName = "";
            switch (measureDate) {
                case "2024-11-21":
                case "2024-12-06":
                    modelName = "cnSteel_01";
                    break;
                // case "2025-01-09":
                case "2025-01-10":
                    modelName = "cnSteel_02";
                    break;
            }
            console.log("measureDate")
            console.log(measureDate)
            console.log(modelName)
            if (t_id == 10 && modelName) {
                // 加載 MTL 和 OBJ 模型
                const mtlLoader = new MTLLoader();
                mtlLoader.setPath('./chinasteel/'); // 替換為你的 mtl 文件路徑
                mtlLoader.load(modelName + '.mtl', (materials) => {
                    materials.preload();
                    const objLoader = new OBJLoader();
                    objLoader.setMaterials(materials);
                    objLoader.setPath('./chinasteel/'); // 替換為你的 obj 文件路徑
                    objLoader.load(modelName + '.obj', (object) => {
                        object.traverse((child) => {
                            if (child.isMesh) {
                                // 設定材質透明屬性
                                child.material.transparent = true; // 啟用透明屬性
                                child.material.opacity = self.chinasteel_opcity * 0.01; // 設定透明度 (0.0 完全透明，1.0 完全不透明)
                            }
                        });
                        // object.position.set(100, 0, 0); // 根據需要調整位置  
                        object.rotateY(2 * Math.PI * (155 / 360));
                        // object.rotateZ(2 * Math.PI * (165 / 360));
                        // object.position.setZ(32 * 0.53);
                        // object.position.setY(-0);//OK
                        // object.position.setX(22 * 0.53);
                        // object.scale.set(0.53, .53, .53);
                        chinasteel_object = object;
                        scene.add(chinasteel_object);

                    });
                });
            }
            /*----重新渲染桶槽----*/
            //0:忘了
            var tankGeometry;

            if (t_id == 0) {
                tankGeometry = this.createTank();
                var circleTop = this.createCircle(this.tankInfo.radiusTop, this.tankInfo.segments, { x: 0, y: this.tankInfo.height, z: 0 });
                var circleBottom = this.createCircle(this.tankInfo.radiusBottom, this.tankInfo.segments, { x: 0, y: 0, z: 0 });
                scene.add(tankGeometry, circleTop, circleBottom);
            }
            //1:奇美-沈澱槽
            else if (t_id == 1) {
                var tankGeometry2 = this.createTank2();
                var tankGeometry22 = this.createTank22();
                var circleTop2 = this.createCircle(15, this.tankInfo.segments, { x: 0, y: 2.5 + 5.7 + 4.4 - 4, z: 0 });
                var circleBottom2 = this.createCircle(15, this.tankInfo.segments, { x: 0, y: 5.7 + 4.4 - 4, z: 0 });
                var circleBottom22 = this.createCircle(6.3, this.tankInfo.segments, { x: 0, y: 4.4 - 4, z: 0 });
                scene.add(tankGeometry22, tankGeometry2, circleTop2, circleBottom2, circleBottom22);

                /*----arrow----*/
                // this.createArrow(scene, tank_h, rotate_radius, rotate_z)
                this.createArrow(scene, 2.5 + 5.7 + 4.4 - 4, 75, -15, 1)
            }
            //2:李長榮-化學槽
            else if (t_id == 2) {
                var tankGeometry3 = this.createTank3();
                var circleTop3 = this.createCircle(5.68, this.tankInfo.segments, { x: 0, y: 14.5, z: 0 });
                var circleBottom3 = this.createCircle(5.68, this.tankInfo.segments, { x: 0, y: 0.52, z: 0 });
                scene.add(tankGeometry3, circleTop3, circleBottom3);

                var tankGeometry32 = this.createTank32();
                var circleTop32 = this.createCircle(5.68 + 0.3, this.tankInfo.segments, { x: 0, y: 0.5, z: 0 });
                var circleBottom32 = this.createCircle(5.68 + 0.3, this.tankInfo.segments, { x: 0, y: 0, z: 0 });
                scene.add(tankGeometry32, circleTop32, circleBottom32);

                // /*----arrow----*/ 
                this.createArrow(scene, 14.5, 90, -0, .38)
            }
            //3:李長榮-T520D
            else if (t_id == 3) {
                tankGeometry = this.createTank6();
                circleTop3 = this.createCircle(7.75 - .05, this.tankInfo.segments, { x: 0, y: 14.5, z: 0 });
                circleBottom3 = this.createCircle(7.75 - .05, this.tankInfo.segments, { x: 0, y: 0, z: 0 });
                scene.add(tankGeometry, circleTop3, circleBottom3);

                // /*----arrow----*/ 
                this.createArrow(scene, 14.5, 90, -0, .43)
            }
            //4:中鋼-F132
            else if (t_id == 4 || t_id == 13) {
                tankGeometry = this.createTank7();
                circleTop3 = this.createCircle(14.4 - .1, this.tankInfo.segments, { x: 0, y: 10, z: 0 });
                circleBottom3 = this.createCircle(14.4 - .1, this.tankInfo.segments, { x: 0, y: 0, z: 0 });
                scene.add(tankGeometry, circleTop3, circleBottom3);


                // /*----arrow----*/ 
                this.createArrow(scene, 10, 90, -0, .9)
            }
            //5:精誠-F132
            else if (t_id == 5) {
                tankGeometry = this.createTank7();
                circleTop3 = this.createCircle(14.4 - .05, this.tankInfo.segments, { x: 0, y: 10, z: 0 });
                circleBottom3 = this.createCircle(14.4 - .05, this.tankInfo.segments, { x: 0, y: 0, z: 0 });
                scene.add(tankGeometry, circleTop3, circleBottom3);

                // /*----arrow----*/ 
                this.createArrow(scene, 10, 90, -0, .9)
            }
            //6:TK-814
            else if (t_id == 6) {
                var geometry6 = new THREE.CylinderGeometry(9.8 / 2 - .05, 9.8 / 2 - .05, 16.8, this.tankInfo.segments, 20);
                var material6 = new THREE.MeshBasicMaterial({ color: 0xffffff });
                var tank6 = new THREE.Mesh(geometry6, material6);
                tank6.position.set(0, 16.8 / 2, 0);

                circleTop3 = this.createCircle(9.8 / 2 - .05, this.tankInfo.segments, { x: 0, y: 16.8, z: 0 });
                circleBottom3 = this.createCircle(9.8 / 2 - .05, this.tankInfo.segments, { x: 0, y: 0, z: 0 });

                scene.add(tank6, circleTop3, circleBottom3);

                // /*----arrow----*/ 
                this.createArrow(scene, this.singleMeasure.tank.height, 90, -0, .3)
            }
            //7:TK853
            else if (t_id == 7) {
                var geometry7 = new THREE.CylinderGeometry(8.8 / 2 - .05, 8.8 / 2 - .05, 16.8, this.tankInfo.segments, 20);
                var material7 = new THREE.MeshBasicMaterial({ color: 0xffffff });
                var tank7 = new THREE.Mesh(geometry7, material7);
                tank7.position.set(0, 16.8 / 2, 0);

                circleTop3 = this.createCircle(8.8 / 2 - .05, this.tankInfo.segments, { x: 0, y: 16.8, z: 0 });
                circleBottom3 = this.createCircle(8.8 / 2 - .05, this.tankInfo.segments, { x: 0, y: 0, z: 0 });

                scene.add(tank7, circleTop3, circleBottom3);

                // /*----arrow----*/ 
                this.createArrow(scene, this.singleMeasure.tank.height, 90, -0, .3)
            }
            else {
                var _h = this.singleMeasure.tank.height;
                var _r = this.singleMeasure.tank.radius;

                var geometry8 = new THREE.CylinderGeometry(_r - .05, _r - .05, _h, this.tankInfo.segments, 20);
                var material8 = new THREE.MeshBasicMaterial({ color: 0xffffff });
                var tank8 = new THREE.Mesh(geometry8, material8);
                tank8.position.set(0, _h / 2, 0);

                circleTop3 = this.createCircle(_r - .05, this.tankInfo.segments, { x: 0, y: _h, z: 0 });
                circleBottom3 = this.createCircle(_r - .05, this.tankInfo.segments, { x: 0, y: 0, z: 0 });

                this.createArrow(scene, this.singleMeasure.tank.height, 90, -0, _r * 0.1 * .7)

                scene.add(tank8, circleTop3, circleBottom3);
            }

            let _index = 1;
            for (var j in this.singleMeasure.tank_measurement_details) {
                const geometry = new THREE.SphereGeometry(0.2);
                // var _hsl = ((this.singleMeasure.tank_measurement_details[j].coating_thickness - self.color_range[0]) / (self.color_range[1] - self.color_range[0]) * 99.9) * 0.7 + 25
                var _hsl = this.hslColorTransform(this.singleMeasure.tank_measurement_details[j].coating_thickness)
                const material = new THREE.MeshStandardMaterial({
                    color: _hsl,
                    transparent: true,
                    depthWrite: false,  // 禁用深度寫入 
                });
                var dot = new THREE.Mesh(geometry, material);
                dot.position.set(
                    -1 * this.singleMeasure.tank_measurement_details[j].x,
                    this.singleMeasure.tank_measurement_details[j].y - (t_id == 1 ? 4 : 0),
                    this.singleMeasure.tank_measurement_details[j].z - (t_id == 0 ? this.tankInfo.radiusTop : 0));
                dot.deep = _hsl;

                dot.measureInfo = this.singleMeasure.tank_measurement_details[j];
                dot.measureInfo.index = _index++;
                dot.coating_thickness = this.singleMeasure.tank_measurement_details[j].coating_thickness;
                dot.name = 'tank_measurement_dot'
                dot.layers.set(1);
                scene.add(dot);
            }
            function animate() {
                //if isChange數值有更動 dotReload

                self.cameraX = camera.position.x;
                self.cameraY = camera.position.y;
                self.cameraZ = camera.position.z;
                renderer.render(scene, camera)
                if (chinasteel_object) {
                    chinasteel_object.traverse((child) => {
                        if (child.isMesh && child.material) {
                            child.material.transparent = true; // 確保透明屬性啟用
                            child.material.depthWrite = true; // 確保透明屬性啟用
                            child.material.opacity = self.chinasteel_opcity * 0.01; // 設置透明度
                            child.material.needsUpdate = true; // 通知材質更新
                        }
                    });
                    chinasteel_object.renderOrder = 1;
                }


                // 為所有 dot 物件加上類似的透明度調整
                for (let i = 0; i < scene.children.length; i++) {
                    if (scene.children[i].name === 'tank_measurement_dot' && scene.children[i].material) {
                        scene.children[i].material.opacity = .9;
                        scene.children[i].material.needsUpdate = true;
                        scene.children[i].material.opacity = self.dot_opcity * 0.01; // 設置透明度 
                        scene.children[i].renderOrder = 2;
                    }
                }

                if (self.showDetail.isChange) {
                    self.showDetail.isChange = false;
                    for (let i = 0; i < scene.children.length; i++) {
                        var _hsl = '#a3a3a3';
                        if (scene.children[i].name && scene.children[i].name == 'tank_measurement_dot') {
                            _hsl = self.hslColorTransform(scene.children[i].coating_thickness)
                            scene.children[i].material.color.set(_hsl);
                            scene.children[i].deep = new THREE.Color(_hsl);
                        }
                    }
                }

                if (!self.stop) requestAnimationFrame(animate);
                controls.update()
            }
            canvas.addEventListener('mouseup', onMouseUp, false);
            canvas.addEventListener('mousedown', onMouseDown, false);
            canvas.addEventListener('mousemove', onMouseMove, false);
            animate();
        },
        clearScene(scene) {
            var i;
            for (i = 0; i < scene.children.length; i++) {
                var obj = scene.children[i];
                scene.remove(obj);
            }
        },
        createTank() {
            /*----幾何----*/
            //CylinderGeometry(radiusTop  , radiusBottom  , height  , radialSegments : 圓切邊, heightSegments : 高切邊, openEnded : 空心 )
            var geometry = new THREE.CylinderGeometry(this.tankInfo.radiusTop - 0.2, this.tankInfo.radiusBottom - 0.2, this.tankInfo.height, this.tankInfo.segments, 20);
            var material = new THREE.MeshBasicMaterial({ color: 0xffffff });
            var tank = new THREE.Mesh(geometry, material);
            tank.position.setY(this.tankInfo.height / 2);
            tank.position.set(0, this.tankInfo.height / 2, 0);
            return tank;
        },
        createTank2() {
            /*----幾何----*/
            //CylinderGeometry(radiusTop  , radiusBottom  , height  , radialSegments : 圓切邊, heightSegments : 高切邊, openEnded : 空心 )
            var geometry = new THREE.CylinderGeometry(15, 15, 2.5, this.tankInfo.segments, 20);
            var material = new THREE.MeshBasicMaterial({ color: 0xffffff });
            var tank = new THREE.Mesh(geometry, material);
            tank.position.set(0, 2.5 / 2 + 5.7 + 4.4 - 4, 0);
            return tank;
        },
        createTank22() {
            /*----幾何----*/
            //CylinderGeometry(radiusTop  , radiusBottom  , height  , radialSegments : 圓切邊, heightSegments : 高切邊, openEnded : 空心 )
            var geometry = new THREE.CylinderGeometry(15, 6.38, 5.7, this.tankInfo.segments, 20);
            var material = new THREE.MeshBasicMaterial({ color: 0xffffff });
            var tank = new THREE.Mesh(geometry, material);
            tank.position.set(0, 5.7 / 2 + 4.4 - 4, 0);
            return tank;
        },
        createTank32() {
            /*----幾何----*/
            //CylinderGeometry(radiusTop  , radiusBottom  , height  , radialSegments : 圓切邊, heightSegments : 高切邊, openEnded : 空心 )
            var geometry = new THREE.CylinderGeometry(5.68 + 0.3, 5.68 + 0.3, 0.5, this.tankInfo.segments, 20);
            var material = new THREE.MeshBasicMaterial({ color: 0xffffff });
            var tank = new THREE.Mesh(geometry, material);
            tank.position.setY(0.5 / 2);
            tank.position.set(0, 0.5 / 2, 0);
            return tank;
        },
        createTank3() {
            /*----幾何----*/
            //CylinderGeometry(radiusTop  , radiusBottom  , height  , radialSegments : 圓切邊, heightSegments : 高切邊, openEnded : 空心 )
            var geometry = new THREE.CylinderGeometry(5.68, 5.68, 14.5, this.tankInfo.segments, 20);
            var material = new THREE.MeshBasicMaterial({ color: 0xffffff });
            var tank = new THREE.Mesh(geometry, material);
            tank.position.setY(-14.5 / 2);
            tank.position.set(0, 14.5 / 2, 0);
            return tank;
        },
        createTank6() {
            /*----幾何----*/
            //CylinderGeometry(radiusTop  , radiusBottom  , height  , radialSegments : 圓切邊, heightSegments : 高切邊, openEnded : 空心 )
            var geometry = new THREE.CylinderGeometry(7.75 - .05, 7.75 - .05, 14.5, this.tankInfo.segments, 20);
            var material = new THREE.MeshBasicMaterial({ color: 0xffffff });
            var tank = new THREE.Mesh(geometry, material);
            tank.position.setY(-14.5 / 2);
            tank.position.set(0, 14.5 / 2, 0);
            return tank;
        },
        createTank7() {
            /*----幾何----*/
            //CylinderGeometry(radiusTop  , radiusBottom  , height  , radialSegments : 圓切邊, heightSegments : 高切邊, openEnded : 空心 )
            var geometry = new THREE.CylinderGeometry(14.13 - .1, 14.13 - .1, 10, this.tankInfo.segments, 20);
            var material = new THREE.MeshBasicMaterial({ color: 0xffffff });
            var tank = new THREE.Mesh(geometry, material);
            tank.position.setY(-10 / 2);
            tank.position.set(0, 10 / 2, 0);
            return tank;
        },
        createCircle(radius, segments, position) {
            const circleGeometry = new THREE.CircleGeometry(radius, segments);

            // Remove center vertex
            const itemSize = 3;
            circleGeometry.setAttribute('position',
                new THREE.BufferAttribute(
                    circleGeometry.attributes.position.array.slice(itemSize,
                        circleGeometry.attributes.position.array.length - itemSize
                    ), itemSize
                )
            );
            circleGeometry.index = null;
            var _c = new THREE.LineLoop(circleGeometry, new THREE.LineBasicMaterial({ color: 'black' }));

            _c.rotateX(2 * Math.PI * (90 / 360));
            _c.position.setX(position.x)
            _c.position.setY(position.y)
            _c.position.setZ(position.z)
            return _c;
        },
        searchDisplay() {
            this.msg = ' '
            if (!this.searchInfo.tank) {
                this.msg = '請填寫完整桶槽資訊';
                this.$store.commit('SetSearchDisplayList', {})
            } else {
                this.GetSearchDisplayList({
                    tank_id: [this.searchInfo.tank.id]
                })
            }
        },
        hideDetail() {
            this.showDetail.show = false;
            var btn = document.getElementById('three');
            btn.click()
        },
        dot_redraw() {
            this.showDetail.isChange = true;
        },
        createArrow(scene, tank_h, rotate_radius, rotate_z, scale) {
            /*----arrow----*/
            const arrow = new THREE.ConeGeometry(1, 3.5, 30);
            const arrowMaterial = new THREE.MeshBasicMaterial({ color: "red" });
            const cone = new THREE.Mesh(arrow, arrowMaterial);

            let c = new THREE.Cylindrical().setFromCartesianCoords(11 * scale, tank_h, 0);//將直角坐標轉成柱座標
            let c_new = new THREE.Cylindrical(c.radius, c.theta - (rotate_radius / 360) * 2 * Math.PI, c.y);
            let v = new THREE.Vector3().setFromCylindrical(c_new);
            cone.position.setZ(v.z);
            cone.position.setY(v.y);
            cone.position.setX(v.x);
            cone.rotateX(2 * Math.PI * (90 / 360));
            cone.rotateZ(2 * Math.PI * (rotate_z / 360))
            cone.scale.set(scale, scale, scale);
            scene.add(cone);

            const arrowBar = new THREE.CylinderGeometry(0.06, 0.06, 7, 32);
            const arrowBarMaterial = new THREE.MeshBasicMaterial({ color: "red" });
            const cylinder = new THREE.Mesh(arrowBar, arrowBarMaterial);
            c = new THREE.Cylindrical().setFromCartesianCoords(7 * scale, tank_h + 0.03, 0);
            c_new = new THREE.Cylindrical(c.radius, c.theta - (rotate_radius / 360) * 2 * Math.PI, c.y)
            v = new THREE.Vector3().setFromCylindrical(c_new);
            cylinder.position.setZ(v.z);
            cylinder.position.setY(v.y);
            cylinder.position.setX(v.x);
            cylinder.rotateX(2 * Math.PI * (90 / 360));
            cylinder.rotateZ(2 * Math.PI * (rotate_z / 360))
            cylinder.scale.set(scale, scale, scale);
            scene.add(cylinder);
            // return cone, cylinder;
        },
        //桶槽資料切換：膜厚、壁厚、桶槽資料初始化init
        //更新資訊：時間軸區間、顯示模式（都預設以第一筆做基準）
        tankDataInit() {
            // this.singleMeasure = this.measureData.list[0];
            // //現在顯示的模式
            // this.measure_type_id = this.singleMeasure.measurement_type_id; 
            //設定時間軸
            this.setTimeLine()
        },
        //單包測量資料切換：只要切換測量資料都需要更新，用id 撈出資料
        //更新資訊：數值區域、時間軸區index、測量點數、測定報告
        singleMeasureInit(id) {
            // this.singleMeasure = this.measureData.list[0];
            var self = this;
            this.measureData.list.forEach(element => {
                if (element.id == id) self.singleMeasure = element;
            });
            this.measure_type_id = this.singleMeasure.measurement_type_id;
            this.initMeasureInit();

            // console.log('this.singleMeasure.file   /  ' + this.singleMeasure.file)
            this.GetTankMeasurementFileBase64(this.singleMeasure.file)

            this.stop = true;
            setTimeout(function () {
                self.stop = false;
                self.initThree();
            }, 50)
            // self.showDetail.isChange = true;
        },
        //初始化大小值
        initMeasureInit() {
            //此為初始化大小值
            var _min = -1, _max = -1;
            for (var _j in this.singleMeasure.tank_measurement_details) {

                if (_j == 0) {
                    _max = this.singleMeasure.tank_measurement_details[_j].coating_thickness;
                    _min = this.singleMeasure.tank_measurement_details[_j].coating_thickness;
                }
                if (_max < this.singleMeasure.tank_measurement_details[_j].coating_thickness)
                    _max = this.singleMeasure.tank_measurement_details[_j].coating_thickness;
                if (_min > this.singleMeasure.tank_measurement_details[_j].coating_thickness)
                    _min = this.singleMeasure.tank_measurement_details[_j].coating_thickness;
            }


            if (this.coating_thickness_min > _min || this.coating_thickness_min == -1) {
                this.coating_thickness_min = _min;
            }
            if (this.coating_thickness_max < _max) {
                this.coating_thickness_max = _max;
            }
            if (this.measure_input_max == -1) {
                this.measure_input_max = _max;
                this.measure_input_min = _min;
                this.measure_range = [this.coating_thickness_min, this.coating_thickness_max];
            }
            // console.log('this.coating_thickness_min : ', this.coating_thickness_min)
            // console.log('this.coating_thickness_max : ', this.coating_thickness_max)
            // console.log('this.measure_input_max : ', this.measure_input_max)
            // console.log('this.measure_input_min : ', this.measure_input_min)
        },
        //數值區域最大最小值 
        m_maxChange(event) {
            var _v = event.target.value;
            var _r = this.measure_range;
            var _max = this.coating_thickness_max;
            if (!isNaN(_v) && Number(_v) <= _max && Number(_v) >= _r[0]) {
                _r[1] = Number(_v);
            } else this.measure_input_max = _r[1]
        },
        m_minChange(event) {
            var _v = event.target.value;
            var _r = this.measure_range;
            var _min = this.coating_thickness_min;
            if (!isNaN(_v) && Number(_v) >= _min && Number(_v) <= _r[1]) {
                _r[0] = Number(_v);
            } else this.measure_input_min = _r[0]
        },
        //蒐集timelineData
        //{1:[]}
        setTimeLine() {
            var data = this.measureData.list;
            var obj = { 1: [], 2: [] }
            for (var i in data) {
                obj[data[i].measurement_type_id].push({
                    id: data[i].id,
                    time: new Date(data[i].date).getFullYear() + '/' + (new Date(data[i].date).getMonth() + 1) + '/' + new Date(data[i].date).getDate()
                })
            }
            this.timeline_item = obj;
        },
        hslColorTransform(measureResults) {
            var color_min = parseInt(this.color_range[0]), color_max = parseInt(this.color_range[1]);
            var measure_min = this.measure_range[0], measure_max = this.measure_range[1];
            // 0 ~ m ~ n ~ 1000
            //20 - 140  
            if (measureResults < measure_min) {
                return 'hsl( 204, 8%, 50%)'
            } else if (measureResults > measure_max) {
                return 'hsl( 204, 8%, 50%)'
            } else {
                var _colorNum;
                _colorNum = ((measureResults - measure_max) * color_min + (measure_min - measureResults) * color_max) / (measure_min - measure_max);
                return 'hsl( ' + _colorNum + ' , 100%, 50%)'
            }
        },
        saveB64Pdf(data) {
            var contentType = 'application/pdf';
            var blob1 = this.$Base64toBlob(data, contentType);
            var blobUrl1 = URL.createObjectURL(blob1);

            window.open(blobUrl1);
        },
        changeMeasureType(typeId) {
            if (this.measure_type_id != typeId) {
                this.measure_type_id = typeId;

                this.timeline_index = 1
                this.measure_input_min = -1;
                this.measure_input_max = -1;
                this.coating_thickness_min = -1;
                this.coating_thickness_max = -1;
                var newId = this.timeline_item[String(this.measure_type_id)][0].id;
                this.singleMeasureInit(newId);
            }


            // if (this.timeline_index == 1) {
            //     var newId = this.timeline_item[String(this.measure_type_id)][0].id;
            //     // self.showDetail.isChange = true;
            //     this.singleMeasureInit(newId);
            // } else {
            //     //回到第一包
            //     this.timeline_index = 1
            // }
        }
    }
}
</script>